// import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import theme from './theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './Pages/Home';
import About from './Pages/About';
import { Footer } from './components/Footer';
import { Term } from './Pages/Term';
import { Privacy } from './Pages/Privacy';
import { EventLink } from './Pages/events/EventLink';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
function App() {
  const client = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={client}>
        <CssBaseline />
        <Router>
          <Box
            //disableGutters // remove the padding on the left and right side
            // maxWidth={'xl'}
            sx={{
              // using Container component will have the equal padding on both sides
              minHeight: '100vh',
              width: '100%',
              // maxWidth: 1200,
              // flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // borderWidth: 2,
              // borderColor: 'red',
              // borderStyle: 'solid',
              // paddingLeft: 0,
              // paddingRight: 0,
              boxSizing: 'border-box',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 3,
              color: '#4D4D4D',
            }}
          >
            <Header />

            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/term' element={<Term />} />
              <Route path='/privacy' element={<Privacy />} />

              <Route path='/el/:token' element={<EventLink />} />
            </Routes>
            <Footer />
          </Box>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
