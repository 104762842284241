import React from 'react';

interface ImagePreviewProps {
  thumbnailSrc: string;
  fullSrc: string;
  altText: string;
  thumbnailSize?: number;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  thumbnailSrc,
  fullSrc,
  altText,
  thumbnailSize = 120,
}) => {
  const openImageInNewTab = () => {
    window.open(fullSrc, '_blank');
  };

  return (
    <img
      src={thumbnailSrc}
      alt={altText}
      style={{
        width: `${thumbnailSize}px`,
        height: `${thumbnailSize}px`,
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: 8,
      }}
      onClick={openImageInNewTab}
    />
  );
};

export default ImagePreview;
