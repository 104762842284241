import React from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { MAX_WIDTH } from '../../constants';
import ScreenOne from '../../assets/screens/1.png';
import { commonStyles, FeatureBlock } from '../common';
export function One() {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        ...commonStyles.marketingBlockOuter,
        backgroundColor: 'rgba(0, 200, 116, 0.20)',
        // height: isMobile ? 898 : 596,
      }}
    >
      <Box
        sx={{
          ...commonStyles.marketingBlockInner,
          flexWrap: 'wrap-reverse',
          paddingLeft: isMobile ? 3 : 7,
          paddingRight: isMobile ? 3 : 7,
        }}
      >
        <img src={ScreenOne} style={{ width: 250, height: 492 }} />
        <FeatureBlock
          isMobile={isMobile}
          title='Make your planning quick and easy'
          body={`With intuitive event creation steps, you can finish creating your event in under a minute! The centralized calendar view makes it easy to check everyone's plans. Once you find an interesting event, join it with just one click.`}
        />
      </Box>
    </Box>
  );
}
