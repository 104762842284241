import axios from 'axios';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST, // Your API base URL
  timeout: 20000,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // const token = await getIdToken(); // Fetch the ID token
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
