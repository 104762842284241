import { useQuery } from '@tanstack/react-query';
import { getEventByToken } from './backend/events';
import { IEvent } from '../../shared/src';
export const useGetEventByToken = ({
  token,
  enabled = true,
}: {
  token: string | undefined;
  enabled?: boolean;
}) => {
  const query = useQuery<{
    event: IEvent;
    expiredAt: Date;
    expired: boolean;
    firstName: string;
    lastName: string;
  }>({
    queryKey: ['event-links', token],
    queryFn: async () => getEventByToken({ token: token as string }),
    enabled,
  });
  return query;
};
