// src/components/Header.tsx
import React from 'react';
import {
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LogoSvg from '../assets/logo.svg';
import theme from '../theme';
import { MAX_WIDTH } from '../constants';
export const Footer: React.FC = () => {
  const location = useLocation(); // Get the current route
  const muiTheme = useTheme();

  const isActive = (path: string) => {
    return location.pathname === path;
  };
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'rgba(0, 200, 116, 0.60)', // Set background to transparent
        // paddingVertical: 100,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          paddingLeft: 2,
          paddingRight: 2,
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: 'none', // Remove box shadow
          color: 'black',
          maxWidth: MAX_WIDTH,
          rowGap: 2,

          // minHeight: 78,
          paddingTop: 3,
          paddingBottom: 3,

          // paddingLeft: isMobile ? '20px' : '140px',
          // paddingRight: isMobile ? '20px' : '140px',
        }}
      >
        <Box
          sx={{
            minWidth: 300,
            maxWidth: 600,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            color='inherit'
            component={Link}
            to='/term'
            sx={{
              // color: isActive('/') ? theme.palette.primary.main : '#4D4D4D',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '0.875rem',
              color: '#4D4D4D',
              fontFamily: 'Urbanist',
            }}
          >
            Terms of service
          </Button>
          <Button
            color='inherit'
            component={Link}
            to='/privacy'
            sx={{
              // color: isActive('/') ? theme.palette.primary.main : '#4D4D4D',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '0.875rem',
              color: '#4D4D4D',
              fontFamily: 'Urbanist',
            }}
          >
            Privacy policy
          </Button>
        </Box>
        <Typography
          variant='subtitle2'
          sx={{
            minWidth: 300,
            maxWidth: 600,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4D4D4D',
          }}
        >
          @2024 GoingTo Inc. All rights reserved
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{
            minWidth: 300,
            maxWidth: 600,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#4D4D4D',
          }}
        >
          Contact us: support@GoingTo.app
        </Typography>
      </Box>
    </Box>
  );
};
