import moment from 'moment';
export function formatTimeRange(startTime: Date, endTime?: Date): string {
  const now = moment();
  const start = moment(startTime);

  const end = moment(endTime);
  const sameDay = start.isSame(end, 'day');
  const isThisYear = start.isSame(now, 'year');

  const startFormat = isThisYear
    ? 'ddd, MMM D, h:mm A'
    : 'ddd, MMM D, YYYY, h:mm A';
  let endFormat = 'ddd, MMM D, YYYY, h:mm A';
  if (!endTime) {
    return start.format('ddd, MMM D, YYYY, h:mm A');
  }
  if (end.isSame(now, 'year') || end.isSame(start, 'year')) {
    endFormat = 'ddd, MMM D, h:mm A';
    if (end.isSame(start, 'day')) {
      endFormat = 'h:mm A';
    }
  }
  //const endFormat = sameDay ? 'h:mm A' : 'ddd, MMM D, YYYY, h:mm A';

  const startString = start.format(startFormat);
  const endString = end.format(endFormat);

  return `${startString} - ${endString}`;
}
export function formatExpirationTime(expiredAt: Date): string {
  const now = moment();
  const expirationMoment = moment(expiredAt);
  const duration = moment.duration(expirationMoment.diff(now));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  let expirationText = 'This shared link will expire in ';

  if (days > 0) {
    expirationText += `${days} day${days > 1 ? 's' : ''}`;
    if (hours > 0) {
      expirationText += ` and ${hours} hour${hours > 1 ? 's' : ''}`;
    }
  } else if (hours > 0) {
    expirationText += `${hours} hour${hours > 1 ? 's' : ''}`;
    if (minutes > 0) {
      expirationText += ` and ${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  } else if (minutes > 0) {
    expirationText += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    expirationText = 'This shared link will expire very soon';
  }

  return expirationText;
}
