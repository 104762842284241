// src/components/Header.tsx
import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import LogoSvg from '../assets/logo.svg';
import theme from '../theme';
import { MAX_WIDTH } from '../constants';
const Logo = styled('img')({
  height: 40,
  marginRight: '16px',
});

const Header: React.FC = () => {
  const location = useLocation(); // Get the current route
  const muiTheme = useTheme();

  const isActive = (path: string) => {
    return location.pathname === path;
  };
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'transparent', // Set background to transparent
        boxShadow: 'none', // Remove box shadow
        color: 'black',
        maxWidth: MAX_WIDTH,
        alignItems: 'center',
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Logo src={LogoSvg} alt='Logo' />
      <Box
        style={{
          marginLeft: 'auto',
          columnGap: isMobile ? 15 : 20,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button
          color='inherit'
          component={Link}
          to='/'
          sx={{
            color: isActive('/') ? theme.palette.primary.main : '#4D4D4D',
            fontWeight: isActive('/') ? 'bold' : 'normal',
            textTransform: 'none',
            fontSize: isMobile ? '1.15rem' : '1.25rem',
            fontFamily: 'Urbanist',
            // marginLeft: 10,
          }}
        >
          Home
        </Button>
        <Button
          color='inherit'
          component={Link}
          to='/about'
          sx={{
            color: isActive('/about') ? theme.palette.primary.main : 'black',
            fontWeight: isActive('/about') ? 'bold' : 'normal',
            textTransform: 'none',
            fontSize: isMobile ? '1.15rem' : '1.25rem',
            fontFamily: 'Urbanist',
          }}
        >
          About us
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
