import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ScreenFour from '../../assets/screens/4.png';
import { commonStyles, FeatureBlock } from '../common';
export function Four() {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        ...commonStyles.marketingBlockOuter,
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
      }}
    >
      <Box
        sx={{
          ...commonStyles.marketingBlockInner,
          flexWrap: 'wrap',
          paddingLeft: isMobile ? 3 : 7,
          paddingRight: isMobile ? 3 : 7,
        }}
      >
        <FeatureBlock
          isMobile={isMobile}
          title='Create clubs with your friends and meet others'
          body={`Your friends have diverse interests. Create clubs with different groups of your friends—it's a fantastic way to bond! Plus, your friends can invite more people to your clubs, giving you the chance to meet others with similar interests!`}
        />
        <img
          src={ScreenFour}
          style={{ width: 250, height: 492, marginLeft: isMobile ? 0 : 56 }}
        />
      </Box>
    </Box>
  );
}
