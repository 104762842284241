import { Box } from '@mui/material';
import React from 'react';
import AndroidSvg from '../../assets/download_on_android.svg';
import IOSSvg from '../../assets/download_on_ios.svg';
export function DownloadApp() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <a
        href={'https://apps.apple.com/app/6547859271'}
        style={{ width: 120, height: 40 }}
        // target={target}
        // rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        <img src={IOSSvg} alt='Logo' style={{ width: 120, height: 40 }} />
      </a>
      <a
        href={
          'https://play.google.com/store/apps/details?id=app.goingto.goingto'
        }
        style={{ width: 150, height: 60 }}
        // target={target}
        // rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        <img src={AndroidSvg} alt='Logo' style={{ width: 150, height: 60 }} />
      </a>
    </Box>
  );
}
