// src/theme.ts
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440, // Set the maximum width for xl breakpoint to 1440px
      },
    },
    palette: {
      primary: {
        main: '#1877F2',
      },
      secondary: {
        main: '#48A9A6',
      },
    },
    typography: {
      fontFamily: ['Urbanist', 'Roboto Flex'].join(','),
      h4: { fontFamily: 'Urbanist', fontWeight: 'bold' },
      h5: { fontFamily: 'Urbanist', fontWeight: 'bold' },
      h6: { fontFamily: 'Urbanist', fontWeight: 'bold' },
      body1: { fontFamily: 'Roboto Flex', fontSize: 18, lineHeight: 1.5 },
      subtitle1: { fontFamily: 'Roboto Flex', fontSize: 20 },
    },
  })
);

export default theme;
