// src/pages/About.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';
import { MAX_WIDTH } from '../constants';
import { BulletText, commonStyles } from '../components/common';
import { common } from '@mui/material/colors';

export const Term: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: MAX_WIDTH,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          // alignSelf: 'flex-end',
          //   borderWidth: 2,
          //   borderColor: 'red',
          //   borderStyle: 'solid',
        }}
      >
        <Typography
          variant='h4'
          sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}
        >
          GoingTo Terms of service
        </Typography>
        <Typography
          variant='h6'
          sx={{ fontWeight: 'bold', alignSelf: 'flex-start', paddingTop: 2 }}
        >
          Last Updated: July 6, 2024
        </Typography>
      </Box>

      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 3 }}>
        1. Introduction
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        Welcome to GoingTo (“we,” “us,” “our”). We appreciate your interest in
        our mobile application (“App”) and related services (collectively,
        “Services”). By accessing or using our Services, you agree to be bound
        by these Terms of Service (“Terms”). If you do not agree with these
        Terms, we kindly ask that you refrain from using our Services.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        2. Eligibility
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        You must be at least 12 years old to use our Services. By using our
        Services, you represent and warrant that you meet this age requirement
        and have the legal capacity to enter into these Terms.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        3. Account Registration
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        To access the features of the app, you need to create an account. You
        agree to provide accurate, current, and complete information and to
        update it as necessary. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        4. User Conduct
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        You agree to use our Services in accordance with all applicable laws and
        regulations. You shall not:
      </Typography>
      <BulletText text='Use our Services for any illegal or unauthorized purpose.' />
      <BulletText
        text='Access, tamper with, or use non-public areas of the Services, our
        computer systems, or the technical delivery systems of our providers.'
      />
      <BulletText
        text='Attempt to probe, scan, or test the vulnerability of any system or
        network or breach any security or authentication measures.'
      />
      <BulletText
        text='Interfere with or disrupt the integrity or performance of the Services
        or the data contained therein.'
      />
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        You agree not to post any user content that:
      </Typography>
      <BulletText
        text={`Is illegal, harmful, threatening, abusive, harassing, defamatory,
        vulgar, obscene, or invasive of another's privacy.`}
      />
      <BulletText
        text={`Infringes any intellectual property rights or other proprietary rights
        of any party.`}
      />
      <BulletText
        text={`Contains software viruses or any other computer code designed to
        interrupt, destroy, or limit the functionality of any computer software
        or hardware.`}
      />
      <Typography variant='body1' sx={commonStyles.body}>
        We reserve the right to remove or disable access to any User Content
        that we believe, in our sole discretion, violates these Terms or is
        otherwise harmful to our Services or users.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        5. Privacy
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Your privacy is important to us. Please review our Privacy Policy, which
        explains how we collect, use, and disclose information from our users.
        By using our Services, you consent to our collection and use of your
        information as described in the Privacy Policy.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        6. Intellectual Property
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        All content and materials on our Services, except for User Content, are
        the property of GoingTo Inc. or its licensors and are protected by
        copyright, trademark, and other intellectual property laws. You may not
        use any of this content or materials without our prior written
        permission.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        7. Disclaimers and Limitation of Liability
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        7.1 Disclaimers
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Our Services are provided "as is" and "as available" without warranties
        of any kind, either express or implied, including, but not limited to,
        implied warranties of merchantability, fitness for a particular purpose,
        title, and non-infringement. We do not warrant that the Services will be
        uninterrupted, error-free, or secure.
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        7.2 Limitation of Liability
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        To the maximum extent permitted by law, we shall not be liable for any
        indirect, incidental, special, consequential, or punitive damages, or
        any loss of profits or revenues, whether incurred directly or
        indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from (i) your use or inability to use the Services;
        (ii) any unauthorized access to or use of our servers and/or any
        personal information stored therein; (iii) any interruption or cessation
        of transmission to or from the Services; (iv) any bugs, viruses, trojan
        horses, or the like that may be transmitted to or through our Services
        by any third party; or (v) any errors or omissions in any content or for
        any loss or damage incurred as a result of the use of any content
        posted, emailed, transmitted, or otherwise made available through the
        Services.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        8. Indemnification
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        You agree to defend, indemnify, and hold harmless GoingTo, its
        affiliates, and their respective officers, directors, employees, and
        agents, from and against any claims, liabilities, damages, losses, and
        expenses, including, without limitation, reasonable legal and accounting
        fees, arising out of or in any way connected with your access to or use
        of the Services or your violation of these Terms.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        9. Termination
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We may terminate or suspend your access to all or part of our Services,
        without prior notice or liability, for any reason, including if you
        breach these Terms. Upon termination, your right to use the Services
        will immediately cease.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        9. Governing Law
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        These Terms shall be governed by and construed in accordance with the
        laws of Delaware, without regard to its conflict of law provisions.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        10. Changes to Terms
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. By continuing to access or use our Services after any
        revisions become effective, you agree to be bound by the revised Terms.
      </Typography>
    </Box>
  );
};
