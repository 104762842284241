import React, { useEffect, useState } from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as TimeIcon } from '../../assets/svgs/time.svg';
import { ReactComponent as LocationIcon } from '../../assets/svgs/location.svg';
import { ReactComponent as WarningIcon } from '../../assets/svgs/warning.svg';
import { ReactComponent as ExpiredIcon } from '../../assets/svgs/expired.svg';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
} from 'react-router-dom';
import { MAX_WIDTH } from '../../constants';
import { useGetEventByToken } from '../../api/events';
import { IEvent } from '../../../shared/src';
import { formatExpirationTime, formatTimeRange } from './util';
import { commonStyles } from '../../components/common';
import ImagePreview from '../../components/common/ImagePreview';
import { DownloadApp } from '../../components/common';
export function EventLink() {
  const muiTheme = useTheme();
  const { token } = useParams<{ token: string }>();

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { refetch } = useGetEventByToken({
    token,
    enabled: !!token,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{
    event: IEvent;
    expiredAt: Date;
    expired: boolean;
    firstName: string;
    lastName: string;
  }>();
  useEffect(() => {
    async function fetchData() {
      const { isLoading, data } = await refetch();
      if (isLoading) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
        setData(data);
      }
    }
    if (token) {
      fetchData();
    }
  }, [token]);
  if (!data) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          // flex: 1,
          // margin: 0,
        }}
      >
        <Typography variant='h4'>No event found</Typography>
      </Box>
    );
  }
  const { event, firstName, lastName, expiredAt } = data;
  console.log(``);
  const name = `${firstName} ${lastName}`;

  if (!token) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          // flex: 1,
          // margin: 0,
        }}
      >
        <Typography variant='h4'>No link token provided</Typography>
      </Box>
    );
  }
  if (isLoading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          // flex: 1,
          // margin: 0,
        }}
      >
        <Typography variant='h4'>Loading...</Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '80vh',
        width: '100%',
        flex: 1,

        // margin: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          // borderWidth: 2,
          // borderColor: 'purple',
          // borderStyle: 'solid',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: data.expired ? 'flex-start' : 'center',
          // alignItems: 'center',
          maxWidth: MAX_WIDTH,
          width: '100%',
          paddingLeft: 1,
          paddingRight: 1,
          flex: 1,
          // height: '100%',
          // height: '90vh',
        }}
      >
        {data.expired ? (
          <Box
            sx={{
              paddingTop: 5,
              paddingBottom: 3,
              alignSelf: 'flex-start',
              // justifyContent: 'center',
              // alignItems: 'center',
              // display: 'flex',
              // flexDirection: 'column',
              maxWidth: 700,
              // flex: 1,
              // margin: 0,
            }}
          >
            <Box sx={commonStyles.flexRow}>
              <Box sx={{ alignSelf: 'flex-start', paddingRight: 0.5 }}>
                <ExpiredIcon />
              </Box>

              <Typography variant='h5'>{`Shared link from ${name} is expired`}</Typography>
            </Box>
            <Box sx={{ paddingLeft: 1, paddingTop: 2 }}>
              <Typography variant='body1'>
                {`Download GoingTo app to learn more about ${name}'s events.`}
              </Typography>
              <DownloadApp />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                maxWidth: 500,
                // width: boxWidth,
                paddingTop: 5,
                paddingBottom: 3,
              }}
            >
              <Typography variant='h5'>{`${name} shared an event with you`}</Typography>
            </Box>
            <Box
              sx={{
                borderColor: '#EDEDED',
                borderWidth: 2,
                borderRadius: 8,
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: 3,
                maxWidth: 500,
                // width: boxWidth,
              }}
            >
              <Typography variant='h4' sx={{}}>
                {event.title}
              </Typography>
              <Box sx={[commonStyles.flexRow, { paddingTop: 1 }]}>
                <TimeIcon />
                <Typography variant='body1' sx={{ paddingLeft: 1 }}>
                  {formatTimeRange(event.startTime, event.endTime)}
                </Typography>
              </Box>
              {event.location && (
                <Box sx={[commonStyles.flexRow, { paddingTop: 1 }]}>
                  <LocationIcon />
                  <Typography variant='body1' sx={{ paddingLeft: 0.6 }}>
                    {event.location}
                  </Typography>
                </Box>
              )}
              {event.description && (
                <Box sx={{ paddingTop: 1 }}>
                  <Typography variant='h6'>Description</Typography>
                  <Typography variant='body1'>{event.description}</Typography>
                </Box>
              )}
              {event.mediaList && event.mediaList.length > 0 && (
                <Box>
                  <Typography variant='h6'>Images</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: 2.5,
                      rowGap: 2.5,
                      flexWrap: 'wrap',
                      paddingTop: 2,
                    }}
                  >
                    {event.mediaList.map((media, index) => (
                      <ImagePreview
                        thumbnailSrc={media.uri}
                        fullSrc={media.uri}
                        altText='image'
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={{ maxWidth: 500 }}>
              <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
                <Box sx={commonStyles.flexRow}>
                  <Box
                    sx={{
                      alignSelf: 'flex-start',
                      paddingTop: 0.15,
                      paddingRight: 0.5,
                    }}
                  >
                    <WarningIcon />
                  </Box>
                  <Box>
                    <Typography variant='body1'>
                      {`This shared link will expire in ${formatExpirationTime(
                        expiredAt
                      )}. Download GoingTo
                      app to interact with your friend.`}
                    </Typography>
                    <DownloadApp />
                  </Box>
                </Box>
                {/* <Typography variant='body1'>
            Download GoingTo app to interact with your friend.
          </Typography> */}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
