// src/pages/Home.tsx
import React from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import IOSSvg from '../assets/download_on_ios.svg';
import AndroidSvg from '../assets/download_on_android.svg';
import background from '../assets/big-G.svg';
import { One } from '../components/marketing/One';
import { MAX_WIDTH } from '../constants';
import { Two } from '../components/marketing/Two';
import { Three } from '../components/marketing/Three';
import ScreenZero from '../assets/screens/0.png';
import theme from '../theme';
import { Four } from '../components/marketing/Four';
import { DownloadApp } from '../components/common';
const Home: React.FC = () => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const bigBGStyle = {
    position: 'absolute',
    right: -50,
    top: -50,
    // maxWidth: 825,
    width: 900,
    height: 978,
    backgroundRepeat: 'no-repeat',
  };
  const mobileBGStyle = {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    right: 0,
    top: 100,
  };
  const bigScreenStyle = {
    position: 'absolute',
    right: '25%',
    top: 150,
    // maxWidth: 825,
    // width: 825,
    // height: 978,
  };
  const mobileScreenStyle = {
    width: '100%',
    position: 'absolute',
    // right: 240,
    top: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        // flex: 1,

        // margin: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          // borderWidth: 2,
          // borderColor: 'purple',
          // borderStyle: 'solid',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // alignItems: 'center',
          maxWidth: MAX_WIDTH,

          width: '100%',
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Box
          sx={[
            {
              zIndex: -2,
              // borderWidth: 2,
              // borderColor: 'red',
              backgroundImage: `url(${background})`,
              //backgroundSize: 'contain', // Adjusts the background to cover the entire container
              // backgroundPosition: 'center', // Centers the background image
              // backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
            },
            isMobile ? mobileBGStyle : bigBGStyle,
          ]}
        ></Box>

        <Box
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
              width: isMobile ? '100%' : '50%',

              height: '100vh',
              // height: 900,
              // borderWidth: 2,
              // borderColor: 'red',
              // borderStyle: 'solid',
              backgroundColor: 'transparent',
              paddingBottom: '20%',
            },
            isMobile ? { paddingTop: 3 } : { justifyContent: 'center' },
          ]}
        >
          {/* <Typography variant='subtitle1' gutterBottom>
            Afraid of friends rejecting your plans?
          </Typography> */}
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              sx={{
                whiteSpace: 'pre-wrap',
                fontWeight: 'bold',
              }}
              gutterBottom
            >
              {'Share what you are '}
            </Typography>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              style={{ fontWeight: 'bold', color: theme.palette.primary.main }}
              gutterBottom
            >
              GoingTo
            </Typography>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              sx={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
              gutterBottom
            >
              {' do,'}
            </Typography>

            {/* <Typography variant='h5' gutterBottom>
              do—no invitations needed.
            </Typography> */}
          </Box>
          <Typography
            sx={{ fontWeight: 'bold' }}
            variant={isMobile ? 'h5' : 'h4'}
            gutterBottom
          >
            Enjoy fun times with friends.
          </Typography>
          {/* <Typography variant='subtitle1' gutterBottom>
            no invitation required
          </Typography> */}

          <DownloadApp />
        </Box>
      </Box>
      <Box
        sx={[
          {
            zIndex: -1,
            // borderWidth: 2,
            // borderColor: 'red',
            // backgroundImage: `url(${background})`,
            //backgroundSize: 'contain', // Adjusts the background to cover the entire container
            // backgroundPosition: 'center', // Centers the background image
            // backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
          },
          isMobile ? mobileScreenStyle : bigScreenStyle,
        ]}
      >
        <img src={ScreenZero} style={{ width: 313, height: 617 }} />
      </Box>

      <One />
      <Two />
      <Three />
      <Four />

      {/* Your content goes here */}
    </Box>
  );
};

export default Home;
