// src/pages/About.tsx
import React from 'react';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { MAX_WIDTH } from '../constants';
import { BulletText, commonStyles } from '../components/common';

export const Privacy: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: MAX_WIDTH,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          //   borderWidth: 2,
          //   borderColor: 'red',
          //   borderStyle: 'solid',
        }}
      >
        <Typography variant='h4' sx={{ alignSelf: 'flex-start' }}>
          GoingTo Privacy Policy
        </Typography>
        <Typography
          variant='h6'
          sx={{ alignSelf: 'flex-start', paddingTop: 2 }}
        >
          Last Updated: July 6, 2024
        </Typography>
      </Box>

      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 3 }}>
        1. Introduction
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        Welcome to GoingTo ("we," "us," "our"). We are committed to protecting
        your privacy and personal information. This Privacy Policy explains how
        we collect, use, disclose, and safeguard your information when you use
        our mobile application.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        2. Information We Collect
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        2.1 Personal Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We collect personal information such as your name and email address when
        you create an account. You may also choose to provide a profile picture,
        which is optional.
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        2.2 User-Generated Content
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        As you use our app, we collect the content you create and share,
        including event details (such as title, description, date, time, and
        location), photos or images related to events, and any messages or
        interactions you make on events.
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        2.3 Automatically Collected Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Our app automatically collects certain information about your device,
        including the device type and operating system. We also collect your IP
        address, data about how you use the app, and, with your permission, your
        location data.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        3. How We Use Your Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We use the collected information to:
      </Typography>
      <BulletText text='Create and manage your account' />
      <BulletText text='Provide and improve our services' />
      <BulletText text='Facilitate event creation and sharing' />
      <BulletText text='Send notifications about events and app updates' />
      <BulletText text='Analyze app usage to enhance user experience' />
      <BulletText text='Comply with legal obligations' />
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        4. Sharing of Your Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        We may share your information with:
      </Typography>
      <BulletText text='Other users (for event sharing and social features)' />
      <BulletText text='Service providers (e.g., hosting, analytics)' />
      <BulletText text='Law enforcement (when required by law)' />
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        We do not sell your personal information to third parties.
      </Typography>

      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        5. Data Security
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We take the security of your personal information seriously and employ
        industry-standard measures to protect it. Our security practices
        include:
      </Typography>
      <BulletText text='Encryption: We use encryption protocols to protect your data during transmission and storage.' />
      <BulletText text='Access controls: We limit access to personal information to authorized personnel who need it to perform their duties.' />
      <BulletText text='Regular security audits: We conduct periodic reviews of our security measures to identify and address potential vulnerabilities.' />
      <BulletText text='Secure data storage: We store your data on secure servers protected by firewalls and other advanced security systems.' />
      <BulletText text='Law enforcement (when required by law)' />
      <Typography variant='body1' sx={commonStyles.body}>
        While we strive to use commercially acceptable means to protect your
        personal information, it's important to note that no method of
        transmission over the internet or electronic storage is 100% secure. As
        such, while we make every effort to protect your personal information,
        we cannot guarantee its absolute security.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        6. Your Rights and Choices
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        You can:
      </Typography>
      <BulletText text='Access, update, or delete your personal information' />
      <BulletText text='Opt-out of marketing communications' />
      <BulletText text='Adjust app permissions (e.g., location access)' />
      <BulletText text='Delete your account' />
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        7. Children's Privacy
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Our app is intended for users aged 12 and above. We do not knowingly
        collect personal information from children under the age of 12. If we
        become aware that we have inadvertently collected personal information
        from a child under 12, we will take steps to delete such information
        promptly. If you are a parent or guardian and believe that your child
        under 12 has provided us with personal information, please contact us so
        we can take appropriate action.
      </Typography>

      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        8. Changes to This Privacy Policy
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Last Updated" date.
      </Typography>
    </Box>
  );
};
