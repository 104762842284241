import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { MAX_WIDTH } from '../../constants';
import ScreenThree from '../../assets/screens/3.png';
import { commonStyles, FeatureBlock } from '../common';
export function Three() {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        ...commonStyles.marketingBlockOuter,
        backgroundColor: 'rgba(0, 119, 227, 0.20)',
      }}
    >
      <Box
        sx={{
          ...commonStyles.marketingBlockInner,
          flexWrap: 'wrap-reverse',
          paddingLeft: isMobile ? 3 : 7,
          paddingRight: isMobile ? 3 : 7,
        }}
      >
        <img src={ScreenThree} style={{ width: 250, height: 492 }} />
        <FeatureBlock
          isMobile={isMobile}
          title='Controlled event sharing'
          body='Concerned about the privacy of your plans? You have complete control over who can see your event, whether you share it with all your friends or just a select few.'
          extraBody='Want to share your plans with more people? You can set your event as forwardable, so your friends can also invite their friends to join too!'
        />
      </Box>
    </Box>
  );
}
