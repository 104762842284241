import { MAX_WIDTH } from '../../constants';

export const commonStyles = {
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  body: { maxWidth: 800 },
  marketingBlockInner: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    columnGap: 7,
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  marketingBlockOuter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bulletText: {
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: 2,
    columnGap: 0.5,
  },
};
