import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ScreenTwo from '../../assets/screens/2.png';
import { commonStyles, FeatureBlock } from '../common';
export function Two() {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        ...commonStyles.marketingBlockOuter,
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
      }}
    >
      <Box
        sx={{
          ...commonStyles.marketingBlockInner,
          flexWrap: 'wrap',
          paddingLeft: isMobile ? 3 : 7,
          paddingRight: isMobile ? 3 : 7,
        }}
      >
        <FeatureBlock
          isMobile={isMobile}
          title='Stress free event hosting / attendance'
          body={`Feeling stressed when inviting people or when you get invited? Try our non-invitation open events. Event creators can easily control who to share the event with, so any event you see is curated for you, meaning you are always welcome to join!`}
          extraBody={`When you have some free time but don't know what to do, use our 'Free Time' feature to share availability with friends and spark a conversation.`}
        />
        <img
          src={ScreenTwo}
          style={{ width: 250, height: 492, marginLeft: isMobile ? 0 : 56 }}
        />
      </Box>
    </Box>
  );
}
