import { Box, Typography } from '@mui/material';
import React from 'react';

export function FeatureBlock({
  title,
  body,
  isMobile,
  extraBody,
}: {
  title: string;
  body: string;
  isMobile: boolean;
  extraBody?: string;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        minWidth: 300,
        paddingBottom: isMobile ? 3 : 0,
      }}
    >
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        sx={{ fontWeight: 'bold' }}
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'normal',
          fontFamily: 'Roboto Flex',
          fontSize: '1.25rem',
        }}
        gutterBottom
      >
        {body}
      </Typography>
      {extraBody && (
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'normal',
            fontFamily: 'Roboto Flex',
            fontSize: '1.25rem',
          }}
        >
          {extraBody}
        </Typography>
      )}
    </Box>
  );
}
