// src/pages/About.tsx
import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { MAX_WIDTH } from '../constants';
import { commonStyles } from '../components/common';

const About: React.FC = () => {
  return (
    <Box
      sx={{
        flex: 1,
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        maxWidth: MAX_WIDTH,

        width: '100%',
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 6,
      }}
    >
      <Typography variant='h4' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        About us
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        We are a small team from Seattle, Washington, with a passion for helping
        people enjoy quality time with their friends. After years of searching
        for a convenient tool to manage social activities with family and
        friends, we decided to create the GoingTo app ourselves.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        Our goal
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        Our aim is to foster strong friendships by minimizing time cost and
        removing technical and psychological barriers. We want to create a safe
        and stress-free environment where users can bond and meet new, quality
        friends.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        Our niche
      </Typography>
      <Typography variant='h6' sx={{}}>
        Focus on the future, not the past
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        Our shared event calendar, club, and chat features encourage users to
        make plans and create new memories together, unlike traditional social
        media that focus on past events. This way, no one feels left out.
      </Typography>
      <Typography variant='h6' sx={{ paddingTop: 2 }}>
        Value strong interactions
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        No "Like" buttons, no "comments"—we eliminate the traditional content
        poster vs. viewer dynamic. In the GoingTo app, everyone is welcome to
        initiate or contribute to an event or chat, fostering equal and positive
        interactions.
      </Typography>
      <Typography variant='h5' sx={{ paddingBottom: 2, paddingTop: 2 }}>
        References
      </Typography>
      <Typography variant='body1' sx={commonStyles.body} gutterBottom>
        In the past, despite our willingness to spend quality time with friends,
        fear of rejection or worry about disturbing others often held us back.
        Through learning from others, we discovered we weren't alone in this.
        Inspired by several studies, we decided to create the GoingTo app to
        help people facing similar challenges.
      </Typography>
    </Box>
  );
};

export default About;
