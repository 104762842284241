import { Typography, Box } from '@mui/material';
import { commonStyles } from './styles';

export function BulletText({ text }: { text: string }) {
  return (
    <Typography sx={commonStyles.bulletText}>
      <Typography
        variant='body1'
        sx={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          maxWidth: 800,
          alignSelf: 'flex-start',
        }}
      >
        ·
      </Typography>
      <Typography variant='body1' sx={{ maxWidth: 800 }} gutterBottom>
        {text}
      </Typography>
    </Typography>
  );
}
